@import 'spinner';

.overlay {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;

	.bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $white;
		opacity: 0.85;
	}

	&.absolute {
		position: absolute;
	}

	.inner {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}