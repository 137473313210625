@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';
@import 'application/src/scss/util/spinner-overlay';

:host {
  display: inline-block;

  &>div {
    display: inline-flex;
    position: relative;
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $white;
    border: 1px solid $medium-gray;
    border-right: 0;
    flex: 1;
    min-height: 42px;
    position: relative;

    input {
      border: 0;
      outline: 0;
      flex: 1 1 auto;
      margin: 0;
      width: 60px;
      text-align: right;
      display: block;
      box-sizing: border-box;
      height: 1em;
      padding: 0 0.55556rem*2 0 0.55556rem;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1;
      color: $black;
      transition: none;
      appearance: none;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .unit {
    flex: 0 1 auto;
    color: $medium-gray;
    padding: 0 0.5rem;
    font-size: 1rem;
    vertical-align: middle;
    line-height: 1;
    position: relative;
    text-align: right;
    white-space: nowrap;

    div {
      &::before {
        position: absolute;
        font-size: 0.7rem;
        line-height: 1rem;
        vertical-align: middle;
        left: -0.25rem;
        content: 'x';
      }
    }
  }

  .message {
    transition: opacity 200ms ease-out;
    position: absolute;
    top: -99999px;
    left: -99999px;
    opacity: 0;
    z-index: -1;
    background-color: $tooltip-background-color;
    color: $tooltip-color;
    width: calc(100% - 1.5rem);
    padding: $tooltip-padding;
    border-radius: $tooltip-radius;
    font-size: $tooltip-font-size;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
    text-align: left;

    b {
      color: $highlight-color;
    }

    &::before {
      @include css-triangle(6px, $tooltip-background-color, down);
      margin-top: -1px;
      position: absolute;
      top: 100%;
      left: 33%;
    }
  }

  button {
    @include button();
    margin: 0;
    padding: $button-padding;
    outline: 0;
    font-size: map-get($button-sizes, large);
    text-decoration: none;

    &:disabled {
      @include button-disabled();
    }
  }
}

:host(.expanded) {
  display: block;
}

:host(.break) {
  &>div {
    flex-direction: column;
  }

  label {
    border: 1px solid $medium-gray;
  }
}

:host(.connected) {
  &>div {
    visibility: visible;
  }
}

:host(.error) {
  .message {
    top: auto;
    bottom: calc(100% - 1px);
    left: 0;
    opacity: 1;
    z-index: 1;
  }
}